.graphContainer {
  display: flex;
  flex-direction: row;
}

.symptomOverviewSide {
  width: 50%;
}

.symptomOverviewContainer {
  height: 252px;
  width: 100%;
  display: flex;
  break-inside: avoid;
  gap: var(--spacing-2);
  padding-bottom: var(--spacing-1);
}
