.defaults {
  display: flex;
  padding: 0;
  margin: 0;
}

.fill {
  height: 100%;
  width: 100%;

  /* Mobile Safari doesn't respect height: 100%
    when none of the ancestors have set height in units */
  flex-grow: 1;
}

.overflow-hidden {
  overflow: hidden;
}

.row {
  flex-direction: row;
}

.column {
  flex-direction: column;
}

.row-reverse {
  flex-direction: row-reverse;
}

.column-reverse {
  flex-direction: column-reverse;
}

.justify-content-start {
  justify-content: flex-start;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-space-between {
  justify-content: space-between;
}

.justify-content-space-evenly {
  justify-content: space-evenly;
}

.align-content-start {
  align-content: flex-start;
}

.align-content-end {
  align-content: flex-end;
}

.align-content-center {
  align-content: center;
}

.align-items-start {
  align-items: flex-start;
}

.align-items-end {
  align-items: flex-end;
}

.align-items-center {
  align-items: center;
}

.align-items-baseline {
  align-items: baseline;
}

.align-items-stretch {
  align-items: stretch;
}

/** wrap */
.wrap {
  flex-wrap: wrap;
}

.nowrap {
  flex-wrap: nowrap;
}

.gap-0-25 {
  gap: var(--spacing-0-25);
}

.gap-0-5 {
  gap: var(--spacing-0-5);
}

.gap-1 {
  gap: var(--spacing-1);
}

.gap-2 {
  gap: var(--spacing-2);
}

.gap-3 {
  gap: var(--spacing-3);
}

.gap-4 {
  gap: var(--spacing-4);
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-shrink-0 {
  flex-shrink: 0;
}
