.mainStop {
  stop-color: var(--background-color);
}

.altStop {
  stop-color: var(--color-blueViolet-light);
}

.whiteAltStop {
  stop-color: var(--color-white);
}

.mainStopWithOpacity {
  stop-color: var(--color-primary-alpha-30);
}

.altStopWithOpacity {
  /* this is --color-blue-violet with 30% opacity */
  stop-color: rgb(244 243 252 / 30%);
}

.transparentRect {
  fill: transparent;
}

.highlightedRect {
  fill: var(--color-primary-alpha-10);
}

.axisLabel {
  fill: var(--color-charcoal-30);
}

.yAxisLabel {
  fill: var(--color-charcoal-60);
}

.verticalAxis {
  position: absolute;
  right: 0;
}
