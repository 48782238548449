.graphContainer {
  display: flex;
  flex-direction: row;
}

.overallSymptomImpactContainer {
  border-radius: var(--radius-xs);
  display: flex;
  flex-direction: row;
  padding: var(--spacing-4) var(--spacing-5);
  justify-content: space-between;
  border: thin solid var(--color-charcoal-5);
  break-inside: avoid;
}

.pressureGaugeContainer {
  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-content: center;
}

.changeContainer {
  display: flex;
  justify-content: flex-end;
}
